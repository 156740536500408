<template>
  <div class="page">
    <Loader v-if="loader"></Loader>
    <div v-if="!loader" class="container fixed">
      <div class="page__title_main with-margin">Ваши данные</div>
      <div class="container__form with-margin">
        <RedactField
          v-for="(field, index) in fields"
          :field="field"
          :error="errors[field.name]"
          v-model="field.value"
          :key="'field_' + index"
        />
      </div>
      <Button @click="submit" text="Сохранить"></Button>

    </div>

  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";
import Loader from "@/components/4laps/Loader.vue";
import RedactField from "@/components/4laps/RedactField.vue";
import { axiosSimpleWrapper } from "@/helpers/dataHelpers";
import { parse } from "libphonenumber-js";
import { notification } from "ant-design-vue";
import json from "@/DUMP/fourLaps/redact.fields.json";
import {validate} from "@/helpers/formValidateHelper";

export default {
  name: "Redact",
  components: {
    RedactField,
    Button,
    Loader,
  },
  data() {
    return {
      phone: "",
      uuid: "",
      code: "",
      loader: true,
      errors: {},
      fields: [],
    };
  },
  // watch: {},
  mounted() {
    this.fields = json;
    if (
      !this.$route.query.phone ||
      !this.$route.query.uuid ||
      !this.$route.query.code
    ) {
      this.$router.push("/");
    } else {
      this.phone = this.$route.query.phone;
      this.uuid = this.$route.query.uuid;
      this.code = this.$route.query.code;
    }
    const req = {
      method: "get",
      url: "/pets/" + this.uuid,
    };
    this.loader = true;
    axiosSimpleWrapper(req, (response) => {
      if (response.data.success) {
        const ownerNameField = this.fields.find(
          (field) => field.name === "owner_name"
        );
        if (ownerNameField) {
          ownerNameField.value = response.data.data.owner_name;
        }
        const petNameField = this.fields.find(
          (field) => field.name === "pet_name"
        );
        if (petNameField) {
          petNameField.value = response.data.data.pet_name;
        }
        const phoneSecondField = this.fields.find(
            (field) => field.name === "phone_2"
        );
        if (phoneSecondField) {
          phoneSecondField.value = response.data.data.phone_2;
        }
        const dopNameField = this.fields.find(
            (field) => field.name === "dop_name"
        );
        if (dopNameField) {
          dopNameField.value = response.data.data.dop_name;
        }
        const phoneField = this.fields.find((field) => field.name === "phone");
        if (phoneField) {
          phoneField.value = response.data.data.phone;
        }
      } else {
        this.$router.push({
          path: "/",
          query: { uuid: this.$route.params.point_url },
        });
      }
      this.loader = false;
    });
  },
  methods: {
    submit() {
      const [err, isValid] = validate(this.errors, this.fields);
      this.errors = err;
      if (isValid) {
        this.loader = true;
        const data = {
          sms_code: this.code,
          phone: this.phone,
        };
        this.fields.forEach((item) => {
          if (item.name === "phone") {
            const parsePhone = parse(item.value, "RU");
            if (parsePhone.phone) {
              data[item.name] = parsePhone.phone;
            } else {
              data[item.name] = item.value;
            }
          }
          // todo fix with delete null fields
          else if (item.name === "phone_2") {
           if  (item.value) {
             data[item.name] = item.value;
           }
          }
          else if (item.name === "dop_name") {
            if  (item.value) {
              data[item.name] = item.value;
            }
          }
          else {
            data[item.name] = item.value;
          }
          // data[item.name] = item.value;
        });
        const req = {
          method: "post",
          url: "/pets/" + this.$route.query.uuid,
          data,
        };
        axiosSimpleWrapper(req, (response) => {
          if (response.data.success) {
            notification.open({
              message: response.data.message,
            });
            this.$router.push({
              path: "/redact-finish",
              query: { uuid: this.uuid },
            });
          } else {
            notification.open({
              message: response.data.message,
            });
          }
          this.loader = false;
        }, (err) => {
          notification.open({
            message: 'Ошибка на стороне сервиса!',
          });
          console.log(err)
          this.loader = false;
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
