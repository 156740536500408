import { parsePhoneNumber } from "libphonenumber-js";

export function validate(errors, fields) {
  errors = {};
  fields.forEach((field) => {
    if (!field.value) {
      field.value = null;
    }
    if (field.required && !field.value) {
      errors[field.name] = "Необходимо заполнить данные";
    }
    if (field.value && field.min && field.value.length < field.min) {
      errors[field.name] = `Минимальное количество знаков: ${field.min}`;
    }
    if (field.value && field.max && field.value.length < field.max) {
      errors[field.name] = `Максимальное количество знаков: ${field.max}`;
    }
    if (field.value && field.type === "phone") {
      if (field.value.length < 6) {
        errors[field.name] = `Слишком короткий номер`;
      } else {
        const phoneNumber = parsePhoneNumber(field.value, "RU");
        if (!phoneNumber.isPossible()) {
          errors[field.name] = "Не существующий номер";
        }
      }
    }
  });
  return [errors, Object.keys(errors).length === 0]
}
